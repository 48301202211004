<template>
  <div>
    <!-- Christmas ad -->
    <!-- <div style="width: 100%; height: 32px; background: #00306c">
      <v-row no-gutters align="center" justify="center">
        <span
          style="
            z-index: 20;
            left: calc50%;
            color: #eee;
            text-align: center;
            font-family: Roboto;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            margin-top: 4px;
          "
          :style="$vuetify.breakpoint.smAndDown ? 'font-size: 16px; margin-top: 6px;' : ''"
          >Різдвяні знижки:&nbsp;
          <span style="color: #c74238; font-weight: 900">-10%</span>&nbsp;
          <span style="text-transform: lowercase">ДО НОВОГО РОКУ</span></span
        >
      </v-row>
      <img
        src="@/assets/img/adsBackground.png"
        width="100%"
        height="32px"
        style="position: absolute; top: 0px; left: 0px; object-fit: cover"
      />
    </div> -->
    <v-app-bar
      :height="
        ($vuetify.breakpoint.xs && $route.name == 'ticket_search_page') ||
        ($vuetify.breakpoint.xs && $route.name == 'routes_detail_page')
          ? 'max-content'
          : $vuetify.breakpoint.smAndDown
          ? 'max-content'
          : $route.name == 'ticket_search_page'
          ? '218px'
          : '108px'
      "
      :style="isMain ? '' : 'box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25)'"
      :color="color ? color : 'white'"
    >
      <v-main>
        <v-container>
          <v-col class="py-0 px-0">
            <v-row
              align="center"
              no-gutters
              justify="start"
              v-if="!$vuetify.breakpoint.xs"
            >
              <v-col
                :cols="isAdmin ? '3' : '1'"
                :xl="isAdmin ? '3' : '1'"
                :lg="isAdmin ? '2' : '1'"
                md="2"
                sm="2"
                style="text-align: left"
              >
                <v-row no-gutters justify="start">
                  <router-link to="/">
                    <img
                      class="pt-2"
                      width="102px"
                      height="80px"
                      src="../assets/img/logoMbus.png"
                      alt="логотип компанії"
                      loading="lazy"
                    />
                  </router-link>
                </v-row>
              </v-col>
              <v-col
                :cols="isAdmin ? '5' : '8'"
                xl="6"
                lg="6"
                md="6"
                sm="8"
                style="align-self: center"
              >
                <v-row
                  :justify="$vuetify.breakpoint.xl ? 'center' : 'end'"
                  align="center"
                  :style="$vuetify.breakpoint.lg ? 'margin-right: 30px;' : ''"
                >
                  <router-link to="/routes" style="margin-right: 28px">
                    <span class="headerItems"
                      >Напрямки та рейси</span
                    ></router-link
                  >
                  <v-menu
                    open-on-hover
                    botoom
                    offset-y
                    v-model="detailRouteMenu"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on" aria-label="Наші маршрути">
                        <span
                          style="cursor: pointer"
                          :class="detailRouteMenu ? 'forPassenger' : ''"
                          >Для пасажирів</span
                        >
                        <v-icon
                          v-if="detailRouteMenu"
                          style="
                            margin-right: 24px;
                            cursor: pointer;
                            font-weight: 400;
                          "
                          :color="!detailRouteMenu ? 'black' : '#085895'"
                          >mdi-chevron-up</v-icon
                        >
                        <v-icon
                          v-else
                          style="
                            margin-right: 24px;
                            cursor: pointer;
                            font-weight: 400;
                          "
                          :color="!detailRouteMenu ? 'black' : '#085895'"
                          >mdi-chevron-down</v-icon
                        >
                      </div>
                    </template>
                    <v-col
                      style="
                        border-radius: 5px;
                        background: #fafafa;
                        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
                      "
                      class="px-0 py-0"
                    >
                      <div
                        class="px-2"
                        :class="
                          item.path == $route.path
                            ? 'activePage'
                            : 'forPassengerItem'
                        "
                        v-for="item in forPassengerItems"
                        :key="item.id"
                        @click="$router.push(item.path)"
                      >
                        <span>{{ item.title }}</span>
                      </div>
                    </v-col>
                  </v-menu>
                  <!-- <router-link to="/our_fleet" style="margin-right: 28px"
                ><span class="headerItems">Автопарк</span></router-link
              > -->
                  <router-link to="/contact"
                    ><span class="headerItems">Контакти</span></router-link
                  >
                </v-row>
              </v-col>
              <v-row no-gutters justify="end">
                <v-col cols="2" xl="3" lg="4" md="3" sm="12" class="px-0">
                  <!-- <v-row
                no-gutters
                :justify="
                  $vuetify.breakpoint.xl || $vuetify.breakpoint.lg
                    ? 'end'
                    : 'center'
                "
                v-if="!loggedUser"
              >
                <div class="backgroundText" @click="showSignInModal = true">
                  <div class="accountIcon" style="margin-right: 12px" />
                  <span class="pointer itemText">Увійти</span>
                </div>
              </v-row> -->
                  <!-- <v-row
                no-gutters
                :justify="
                  $vuetify.breakpoint.xl || $vuetify.breakpoint.lg
                    ? 'end'
                    : 'center'
                "
                v-if="loggedUser"
              >
                <v-menu
                  open-on-hover
                  botoom
                  offset-y
                  nudge-left="15px"
                  v-model="menu"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      class="backgroundText"
                      v-bind="attrs"
                      v-on="on"
                      aria-label="Ваш профіль"
                    >
                      <div class="accountIcon" style="margin-right: 12px" />
                      <span class="pointer itemText">Ваш профіль</span>
                    </div>
                  </template>
                  <div
                    style="
                      width: max-content;
                      border-radius: 5px;
                      background: #fafafa;
                      box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
                    "
                  >
                    <div
                      class="menuItem"
                      @click="$router.push('/edit_profile')"
                    >
                      <div class="accountIcon" style="margin-right: 12px" />
                      <span>Персональні дані</span>
                    </div>
                    <div
                      class="menuItem"
                      v-if="
                        (loggedUser && loggedUser.role_id == 1) ||
                        (loggedUser && loggedUser.role_id == 2)
                      "
                      @click="$router.push('/admin/0')"
                    >
                      <div class="adminIcon" style="margin-right: 12px" />
                      <span>Адмін</span>
                    </div>
                    <div class="menuItem" @click="showMyTicketModal = true">
                      <div class="ticketIcon" style="margin-right: 12px" />
                      <span>Мої квитки</span>
                    </div>
                    <div class="menuItem" @click="logout">
                      <div class="logoutIcon" style="margin-right: 12px" />
                      <span>Вийти з акаунту</span>
                    </div>
                  </div>
                </v-menu>
              </v-row> -->
                  <!-- <v-row
              no-gutters
              :justify="
                $vuetify.breakpoint.xl || $vuetify.breakpoint.lg
                  ? 'end'
                  : 'center'
              "
              align="center"
              v-else
            >
              <v-menu
                open-on-hover
                botoom
                offset-y
                min-width="200px"
                nudge-left="30px"
                v-model="menu"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                    <v-row no-gutters align="center" justify="end">
                      <img
                        class="mr-1"
                        src="../assets/img/profileIcon.svg"
                        alt="іконка профілю"
                      />
                      <span class="pointer myCabinet">Мій кабінет</span>
                      <v-icon small class="pointer ml-1" style="color: black"
                        >mdi-chevron-down</v-icon
                      >
                    </v-row>
                  </div>
                </template>
                <v-card
                  min-width="200px"
                  height="110px"
                  style="overflow: hidden"
                >
                  <v-row no-gutters justify="center">
                    <v-col style="text-align: left">
                      <v-row
                        no-gutters
                        align="center"
                        style="padding: 10px 10px 10px 10px"
                      >
                        <v-icon style="margin-right: 5px" color="black"
                          >mdi-account</v-icon
                        >
                        <span
                          style="
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 16px;
                            letter-spacing: 0.1em;
                            color: #000000;
                          "
                          >{{ loggedUser.first_name }}&nbsp;{{
                            loggedUser.last_name
                          }}</span
                        >
                        <v-row no-gutters align="center" justify="end">
                          <img
                            height="20px"
                            width="20px"
                            class="pointer ml-3"
                            src="../assets/img/editProfileIcon.svg"
                            @click="$router.push('/edit_profile')"
                            alt="іконка профілю"
                          />
                        </v-row>
                      </v-row>
                      <v-divider />
                      <v-col class="py-0 px-0" style="text-align: left">
                        <div style="height: 66px">
                          <div
                            class="dropdownItem"
                            @click="showMyTicketModal = true"
                          >
                            <span class="myTicketLabel">Мої квитки</span>
                          </div>
                          <div
                            class="dropdownItem"
                            @click="showConfirmModal = true"
                          >
                            <p>
                              <v-row no-gutters align="center" class="pt-4">
                                <output class="logOutLabel"> Вихід </output
                                ><img
                                  style="margin-left: 5px"
                                  src="../assets/img/logoutIcon.svg"
                                  alt="логаут"
                                />
                              </v-row>
                            </p>
                          </div>
                        </div>
                      </v-col>
                    </v-col>
                  </v-row>
                </v-card>
              </v-menu>
            </v-row> -->
                </v-col>
                <v-row no-gutters align="center" justify="end">
                  <v-menu
                    open-on-hover
                    botoom
                    offset-y
                    :nudge-left="loggedUser ? '15px' : ''"
                    v-model="menu"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <div
                        class="myTicketCard"
                        style="margin-left: 8px"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <div class="accountIcon" style="margin-right: 12px" />
                        <span>Профіль</span>
                      </div>
                    </template>
                    <div
                      style="
                        width: max-content;
                        border-radius: 5px;
                        background: #fafafa;
                        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
                      "
                    >
                      <v-col class="px-0 py-0" v-if="loggedUser">
                        <div
                          class="menuItem"
                          @click="$router.push('/edit_profile')"
                        >
                          <div class="accountIcon" style="margin-right: 12px" />
                          <span>Персональні дані</span>
                        </div>
                        <div
                          class="menuItem"
                          v-if="
                            (loggedUser && loggedUser.role_id == 1) ||
                            (loggedUser && loggedUser.role_id == 2)
                          "
                          @click="$router.push('/admin/0')"
                        >
                          <div class="adminIcon" style="margin-right: 12px" />
                          <span>Адмін</span>
                        </div>
                        <div class="menuItem" @click="showMyTicketModal = true">
                          <div class="ticketIcon" style="margin-right: 12px" />
                          <span>Мої квитки</span>
                        </div>
                        <div
                          class="menuItem"
                          @click="logout"
                          style="border-top: 1px solid #ececec"
                        >
                          <div class="logoutIcon" style="margin-right: 12px" />
                          <span>Вийти з акаунту</span>
                        </div>
                      </v-col>
                      <v-col class="px-0 py-0" v-else>
                        <div
                          class="menuItem"
                          style="width: 120px"
                          @click="showSignInModal = true"
                        >
                          <div class="logoutIcon" style="margin-right: 12px" />
                          <span>Увійти</span>
                        </div>
                      </v-col>
                    </div>
                  </v-menu>
                  <v-menu offset-y open-on-hover nudge-left="70px">
                    <template v-slot:activator="{ on, attrs }">
                      <div
                        class="contactBackground"
                        style="margin-left: 8px"
                        v-bind="attrs"
                        v-on="on"
                        aria-label="Детальна інформація"
                      >
                        <div class="contactIcon" />
                      </div>
                    </template>
                    <div
                      style="
                        width: max-content;
                        padding: 20px 12px;
                        border-radius: 5px;
                        background: #fafafa;
                        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
                      "
                    >
                      <!-- <v-row
                  no-gutters
                  v-for="social in socialNetworks"
                  :key="social.id"
                  style="margin-bottom: 12px"
                  align="center"
                >
                  <a
                    :href="social.value"
                    style="text-decoration: none; color: black"
                    target="_blank"
                  >
                    <v-row no-gutters align="center">
                      <img
                        :src="social?.image_id?.path"
                        :alt="social.key"
                        width="24px"
                        height="24px"
                        style="object-fit: cover; margin-right: 12px;"
                      />
                      <span class="pointer">
                        {{ social.key }}
                      </span>
                    </v-row>
                  </a>
                </v-row> -->
                      <v-row
                        no-gutters
                        v-for="number in phoneNumbersForOrder"
                        :key="number.id"
                        class="mb-1"
                        align="center"
                      >
                        <img
                          v-if="number.type == 'UA'"
                          width="20px"
                          height="20px"
                          src="../assets/img/UA.png"
                          style="margin-right: 12px"
                          alt="Український стяг"
                          loading="lazy"
                        />
                        <img
                          v-if="number.type == 'PL'"
                          width="20px"
                          height="20px"
                          src="../assets/img/PL.png"
                          style="margin-right: 12px"
                          alt="Польський стяг"
                          loading="lazy"
                        />
                        <span class="pointer">
                          <a
                            :href="`tel: ${number.value}`"
                            style="text-decoration: none; color: black"
                            target="_blank"
                          >
                            {{ number.value | VMask("+## ### #### ###") }}</a
                          ></span
                        >
                      </v-row>
                    </div>
                  </v-menu></v-row
                >
              </v-row>

              <!-- <v-col
            :cols="isAdmin ? '2' : '1'"
            style="align-self: center; align-items: center"
          >
            <v-row no-gutters align="center" justify="end">
              <v-menu open-on-hover botoom offset-y max-width="200px">
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                    <v-row no-gutters align="center" justify="end">
                      <img
                        v-if="phoneNumbers[0].type == 'UA'"
                        src="../assets/img/UA.png"
                        width="15px"
                        height="15px"
                        alt="Український стяг"
                      />
                      <img
                        v-if="phoneNumbers[0].type == 'PL'"
                        src="../assets/img/PL.png"
                        width="15px"
                        height="15px"
                        alt="Польський стяг"
                      />
                      <span style="margin-left: 5px">{{
                        phoneNumbers[0].number
                      }}</span>
                      <v-icon>mdi-chevron-down</v-icon>
                    </v-row>
                  </div>
                </template>
                <v-card>
                  <v-row no-gutters justify="center">
                    <v-list>
                      <v-col>
                        <v-row
                          no-gutters
                          v-for="number in phoneNumbers"
                          :key="number.id"
                          class="mb-1"
                          align="center"
                        >
                          <img
                            v-if="number.type == 'UA'"
                            width="15px"
                            height="15px"
                            src="../assets/img/UA.png"
                            class="mr-2"
                            alt="Український стяг"
                          />
                          <img
                            v-if="number.type == 'PL'"
                            width="15px"
                            height="15px"
                            src="../assets/img/PL.png"
                            class="mr-2"
                            alt="Польський стяг"
                          />
                          <span class="pointer">
                            <a
                              :href="`tel: ${number.number}`"
                              style="text-decoration: none; color: black"
                            >
                              {{ number.number }}</a
                            ></span
                          >
                        </v-row>
                      </v-col>
                    </v-list>
                  </v-row>
                </v-card>
              </v-menu></v-row
            >
          </v-col> -->
            </v-row>
            <v-row
              v-else
              align="center"
              justify="end"
              no-gutters
              style="padding: 20px 0px 20px 0px"
              :style="
                ($route.name == 'ticket_search_page' &&
                  $vuetify.breakpoint.xs) ||
                ($route.name == 'routes_detail_page' && $vuetify.breakpoint.xs)
                  ? 'padding-bottom: 12px;'
                  : ''
              "
            >
              <div style="display: flex; width: calc(50% + 30px)">
                <img
                  src="../assets/img/logoMbus.png"
                  width="60px"
                  height="45px"
                  alt="логотип компанії"
                  style="z-index: 90"
                  @click="$router.push('/')"
                  loading="lazy"
                />
                <v-row no-gutters justify="end">
                  <div
                    class="contactBackground"
                    style="margin-left: 8px"
                    aria-label="Детальна інформація"
                    @click="showMobileContactDrawer = true"
                  >
                    <div class="contactIcon" />
                  </div>
                </v-row>
              </div>
            </v-row>
            <v-row
              no-gutters
              justify="center"
              class="pt-7"
              v-if="
                $route.name == 'ticket_search_page' && !$vuetify.breakpoint.xs
              "
            >
              <div style="width: 100%">
                <v-main>
                  <v-container style="padding: 0px">
                    <search-routes-field />
                  </v-container>
                </v-main>
              </div>
            </v-row>
            <v-row
              no-gutters
              justify="center"
              class="pt-2"
              style="margin-bottom: 12px"
              v-if="
                ($route.name == 'ticket_search_page' &&
                  $vuetify.breakpoint.xs) ||
                ($route.name == 'routes_detail_page' && $vuetify.breakpoint.xs)
              "
            >
              <mobile-search-menu @show="showQuantityPassangerModal = true" />
            </v-row>
          </v-col>
        </v-container>
      </v-main>
    </v-app-bar>
    <sign-up-modal
      v-if="showSignUpModal"
      :visible="showSignUpModal"
      @close="showSignUpModal = false"
      @goToSignIn="(showSignUpModal = false), (showSignInModal = true)"
    />
    <sign-in
      v-if="showSignInModal"
      :visible="showSignInModal"
      @close="showSignInModal = false"
      @resetPassword="
        (showSignInModal = false), (showResetPasswordModal = true)
      "
      @goToSignUp="(showSignInModal = false), (showSignUpModal = true)"
    />
    <confirm-modal
      v-if="showConfirmModal"
      @close="showConfirmModal = false"
      :visible="showConfirmModal"
      modalText="Ви підтверджуєте вихід?"
      @confirm="logout"
    />
    <my-ticket-modal
      v-if="showMyTicketModal"
      :visible="showMyTicketModal"
      @close="showMyTicketModal = false"
    />
    <reset-password-modal
      v-if="showResetPasswordModal"
      :visible="showResetPasswordModal"
      @close="showResetPasswordModal = false"
    />
    <mobile-contact-drawer
      v-if="showMobileContactDrawer"
      :socialNetworks="socialNetworks"
      :phoneNumbersForOrder="phoneNumbersForOrder"
      @close="showMobileContactDrawer = false"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import signIn from "./forAdmin/Auth/desktop/signIn.vue";
import signUpModal from "./forAdmin/Auth/desktop/signUpModal.vue";
import ResetPasswordModal from "./forAdmin/resetPassword/resetPasswordModal.vue";
import MyTicketModal from "./forUser/myTicket/myTicketModal.vue";
import MobileSearchMenu from "./UI/mobileSearchMenu.vue";
import ConfirmModal from "./UI/modals/confirmModal.vue";
import searchRoutesField from "./UI/searchRoutesField.vue";
import settingsService from "@/requests/admin/settingsService";
import MobileContactDrawer from "./mobileContactDrawer.vue";
import parsePhoneNumber from "libphonenumber-js";
export default {
  components: {
    signUpModal,
    signIn,
    ConfirmModal,
    MyTicketModal,
    searchRoutesField,
    MobileSearchMenu,
    ResetPasswordModal,
    MobileContactDrawer,
  },
  name: "appHeader",
  data: () => ({
    forPassengerItems: [
      {
        id: 1,
        title: "Автопарк",
        path: "/our_fleet",
      },
      {
        id: 2,
        title: "Нерегулярні перевезення",
        path: "/irregular_transportation",
      },
      {
        id: 3,
        title: "Правила перевезення",
        path: "/transportation_rules",
      },
    ],
    isActiveMenu: "",
    isAdmin: true,
    showSignInModal: false,
    showSignUpModal: false,
    showConfirmModal: false,
    showMyTicketModal: false,
    showResetPasswordModal: false,
    menu: false,
    detailRouteMenu: false,
    showMobileContactDrawer: false,
    selectedItem: 0,
    socialNetworks: [],
    phoneNumbersForOrder: [],
  }),
  props: {
    color: {
      require: false,
    },
    isMain: {
      require: false,
    },
  },
  mounted() {
    if (
      Object.values(this.$route.query).length > 0 &&
      this.$route.query.showMyTickets
    ) {
      this.showMyTicketModal = true;
    }
    this.getSettings();
  },
  methods: {
    async getSettings() {
      await settingsService.getSettingList().then((res) => {
        if (res.status == "Success") {
          this.socialNetworks = res.data.filter(
            (data) => data.type == "socials"
          );
          this.phoneNumbersForOrder = res.data
            .filter((data) => data.type == "contact")
            .map(
              (item) =>
                (item = {
                  id: item.id,
                  key: item.key,
                  value: item.value,
                  type: parsePhoneNumber(item.value, "US").country,
                })
            );
        }
      });
    },
    logout() {
      this.$store.commit("clearUserLogged");
      this.$router.push("/");
      this.showConfirmModal = false;
    },
  },
  computed: {
    ...mapGetters(["loggedUser"]),
    ...mapGetters(["phoneNumbers"]),
  },
  watch: {
    loggedUser: {
      deep: true,
      handler() {
        this.menu = false;
      },
    },
    phoneNumbers: {
      deep: true,
      handler() {
        this.phoneNumbersForOrder = Object.entries(this.phoneNumbers)
          .map(([key, value]) => ({ id: key, ...value }))
          .filter((number) => number.type == "contact");
      },
    },
  },
};
</script>

<style scoped>
.headerItems {
  cursor: pointer;
  color: black;
}
.forPassengerItem {
  padding: 12px;
  text-align: left;
  cursor: pointer;
  user-select: none;
  color: #1b1b1b;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.forPassengerItem:hover {
  background: #0858951a;
  color: #085895;
}
.activePage {
  padding: 12px;
  text-align: left;
  cursor: pointer;
  user-select: none;
  color: #085895;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.forPassenger {
  color: #085895;
}
a {
  text-decoration: none;
  color: black;
}
.myCabinet {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.1em;
  color: #000000;
}
.myTicketLabel {
  padding-left: 15px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.1em;
  color: #243949;
}
.logOutLabel {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.1em;
  color: #960909;
  padding-left: 15px;
}
.dropdownItem {
  height: 26px;
  margin: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.dropdownItem:hover {
  background: rgba(8, 88, 149, 0.1);
  border-radius: 10px;
  height: 26px !important;
}
.forPassangerSection {
  padding: 10px 5px 10px 5px;
}
.forPassangerSection:hover {
  background: silver;
}
.backgroundText {
  width: max-content;
  height: 40px;
  padding: 8px 12px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
  border-radius: 5px;
}
.backgroundText:hover {
  background-color: #cbe0f0;
}
.itemText {
  color: #085895;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.myTicketCard {
  display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background: transparent;
  color: #1b1b1b;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  width: max-content;
  height: 40px;
  cursor: pointer;
  user-select: none;
}
.myTicketCard:hover {
  color: #085895;
  background: #cbe0f0;
}
.myTicketCard .accountIcon {
  background-color: #4c5d6c;
}
.myTicketCard:hover .accountIcon {
  background-color: #085895;
}
.menuItem {
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: left;
  color: #1b1b1b;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  width: 100%;
  cursor: pointer;
  user-select: none;
}
.menuItem:hover {
  background: rgba(8, 88, 149, 0.1);
  color: #085895;
}
.menuItem:hover .accountIcon {
  background-color: #085895;
}
.menuItem:hover .ticketIcon {
  background-color: #085895;
}
.menuItem:hover .adminIcon {
  background-color: #085895;
}
.menuItem:hover .logoutIcon {
  background-color: #085895;
}
.menuItem .accountIcon {
  background-color: #4c5d6c;
}
.menuItem .ticketIcon {
  background-color: #4c5d6c;
}
.contactBackground {
  border-radius: 10px;
  background: rgba(8, 88, 149, 0.1);
  padding: 12px;
}
.contactBackground:hover {
  background-color: #085895;
}
.contactBackground:hover .contactIcon {
  background-color: #eeeeee;
}
.contactBtn {
  border-radius: 10px;
  background: var(--blue, #085895);
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.24);
  height: 48px;
  padding: 8px 12px;
  width: max-content;
}
.accountIcon {
  width: 24px;
  height: 24px;
  background-color: #085895;
  -webkit-mask: url("../assets/img/iconsSvg/accountIcon.svg") no-repeat center;
  mask: url("../assets/img/iconsSvg/accountIcon.svg") no-repeat center;
}
.ticketIcon {
  width: 24px;
  height: 24px;
  background-color: #eee;
  -webkit-mask: url("../assets/img/iconsSvg/ticketHeaderIcon.svg") no-repeat
    center;
  mask: url("../assets/img/iconsSvg/ticketHeaderIcon.svg") no-repeat center;
}
.contactIcon {
  width: 24px;
  height: 24px;
  background-color: #085895;
  -webkit-mask: url("../assets/img/iconsSvg/contactIcon.svg") no-repeat center;
  mask: url("../assets/img/iconsSvg/contactIcon.svg") no-repeat center;
}
.chevron-down {
  width: 14px;
  height: 24px;
  background-color: #085895;
  -webkit-mask: url("../assets/img/iconsSvg/chevron-down.svg") no-repeat center;
  mask: url("../assets/img/iconsSvg/chevron-down.svg") no-repeat center;
}
.adminIcon {
  width: 24px;
  height: 24px;
  background-color: #4c5d6c;
  -webkit-mask: url("../assets/img/iconsSvg/headerAdminIcon.svg") no-repeat
    center;
  mask: url("../assets/img/iconsSvg/headerAdminIcon.svg") no-repeat center;
}
.logoutIcon {
  width: 24px;
  height: 24px;
  background-color: #4c5d6c;
  -webkit-mask: url("../assets/img/iconsSvg/logoutIcon.svg") no-repeat center;
  mask: url("../assets/img/iconsSvg/logoutIcon.svg") no-repeat center;
}
a:hover {
  color: #085895 !important;
}
</style>