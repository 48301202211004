import axios from 'axios';
import authService from '@/requests/admin/authService';
import store from '@/store';

axios.interceptors.response.use(
  response => {
    return Promise.resolve(response);
  },
  async error => {
    if (typeof error == 'object') {
      const { status } = error.response;
      if (status === 401) {
        await authService.refreshToken().then((res) => {
          if (res.data.status == 'success') {
            error.config.headers['Authorization'] = 'Bearer ' + res.data.authorisation.token;
            store.commit('setLoader', false)
            return axios.request(error.config);
          }
        })
      }
    }
    return Promise.reject(error);
  }
);

//const SERVER_API = 'https://api-prod.mbus.if.ua/api' //PROD API
const SERVER_API = 'https://api-dev.mbus.if.ua/api'; //DEV API

export default {
  get(url, headers = {}) {
    return axios.get(SERVER_API + url, headers);
  },
  post(url, body = {}, headers = {}, config = {}) {
    return axios.post(
      SERVER_API + url,
      body,
      headers,
      config,
    );
  },
  delete(url, headers = {}, config = {}) {
    return axios.delete(SERVER_API + url, headers, config);
  },
  put(url, body = {}, config = {}) {
    return axios.put(
      SERVER_API + url,
      body,
      config,
    );
  },
  postExternal(url, body = {}, config = {}) {
    return axios.post(
      SERVER_API + url,
      body,
      config,
    );
  },
};