<template>
  <div class="passangerBox">
    <v-row
      no-gutters
      align="center"
      justify="space-between"
      style="border-bottom: 1px solid #f5f5f5; padding: 12px 0px"
    >
      <v-col
        cols="12"
        xl="4"
        lg="4"
        md="4"
        sm="12"
        class="px-0 py-0"
        style="text-align: left"
      >
        <p style="margin-bottom: 4px" class="passangerTitle">
          {{ passanger.first_name }}&nbsp;{{ passanger.last_name }}
        </p>
        <span class="passangerText" style="color: #4b5262; margin-top: 0px">{{
          passanger.email
        }}</span>
      </v-col>
      <v-col
        cols="12"
        xl="3"
        lg="3"
        md="3"
        sm="12"
        class="px-0 py-0"
        style="text-align: left"
        :style="$vuetify.breakpoint.smAndDown ? 'margin-top: 8px' : ''"
      >
        <p style="margin-bottom: 0px">
          <span class="passangerTitle" style="font-weight: 400">Тип:</span
          >&nbsp;
          {{ passanger?.discount_info?.name || "Дорослий" }}
        </p>
      </v-col>
      <v-col
        cols="12"
        xl="3"
        lg="3"
        md="3"
        sm="12"
        class="px-0 py-0"
        style="text-align: left"
        :style="$vuetify.breakpoint.smAndDown ? 'margin-top: 8px' : ''"
      >
        <p style="margin-bottom: 0px">
          <span class="passangerTitle" style="font-weight: 400">Місце:</span
          >&nbsp;Вільне
        </p>
      </v-col>
      <v-col
        cols="12"
        xl="2"
        lg="2"
        md="2"
        sm="12"
        class="px-0 py-0"
        :style="$vuetify.breakpoint.smAndDown ? 'margin-top: 8px' : ''"
      >
        <v-row no-gutters align="center" justify="center">
          <div
            v-if="status == 'Payed'"
            class="iconBackground"
            @click="$emit('downloadTicketByIndex')"
          >
            <div class="downloadTicketIcon" />
          </div>
          <div
            class="iconBackground"
            style="margin-left: 20px"
            @click="isDetail = !isDetail"
            v-if="!isHiddeDetailBtn"
          >
            <div
              class="chevronDownIcon"
              :style="isDetail ? 'transform:rotate(180deg)' : ''"
            />
          </div>
        </v-row>
      </v-col>
    </v-row>

    <v-expand-transition>
      <v-col v-if="isDetail" class="px-0 py-0" style="margin-top: 12px">
        <v-row no-gutters align="center">
          <v-col cols="3" class="py-0" style="padding-right: 20px">
            <span class="passangerSubtitle">Ім’я</span>
            <p class="passangerText" style="margin-bottom: 20px">
              {{ passanger.first_name }}
            </p>
          </v-col>
          <v-col cols="3" class="py-0" style="padding-right: 20px">
            <span class="passangerSubtitle">Прізвище</span>
            <p class="passangerText" style="margin-bottom: 20px">
              {{ passanger.last_name }}
            </p>
          </v-col>
          <v-col cols="3" class="py-0" style="padding-right: 20px">
            <span class="passangerSubtitle">Місце</span>
            <p class="passangerText" style="margin-bottom: 20px">Вільне</p>
          </v-col>
        </v-row>
        <v-row no-gutters align="center">
          <v-col cols="3" class="py-0" style="padding-right: 20px">
            <span class="passangerSubtitle">Тип квитка</span>
            <p class="passangerText" style="margin-bottom: 20px">
              {{ passanger?.discount_info?.name || "Дорослий" }}
            </p>
          </v-col>
          <v-col cols="3" class="py-0" style="padding-right: 20px">
            <span class="passangerSubtitle">Телефон</span>
            <p class="passangerText" style="margin-bottom: 20px">
              {{ passanger.phone_number }}
            </p>
          </v-col>
          <v-col cols="4" class="py-0" style="padding-right: 20px">
            <span class="passangerSubtitle">Email</span>
            <p class="passangerText" style="margin-bottom: 20px">
              {{ passanger.email }}
            </p>
          </v-col>
        </v-row>
      </v-col>
    </v-expand-transition>
  </div>
</template>

<script>
export default {
  data: () => ({
    isDetail: false,
  }),
  props: {
    passanger: {
      require: true,
    },
    isHiddeDetailBtn: {
      require: false,
    },
    status: {
      require: true,
    },
  },
};
</script>

<style scoped>
.passangerBox {
  font-size: 16px;
  font-weight: 600;
  color: #1b1b1b;
  font-family: Roboto;
  font-style: normal;
  line-height: normal;
}
.passangerText {
  font-size: 16px;
  font-weight: 400;
  margin-top: 4px;
}
.passangerTitle {
  font-size: 16px;
  font-weight: 600;
}
.passangerSubtitle {
  font-size: 14px;
  font-weight: 400;
  color: #4b5262;
}
.iconBackground {
  border-radius: 10px;
  background: rgba(8, 88, 149, 0.1);
  padding: 8px;
  width: 40px;
  height: 40px;
  cursor: pointer;
}
.iconBackground:hover {
  background-color: #144fa9;
}
.iconBackground:hover .downloadTicketIcon {
  background-color: #fafafa;
}
.iconBackground:hover .chevronDownIcon {
  background-color: #fafafa;
}
.downloadTicketIcon {
  width: 24px;
  height: 24px;
  background-color: #144fa9;
  -webkit-mask: url("../../../assets/img/iconsSvg/downloadTicketIcon.svg")
    no-repeat center;
  mask: url("../../../assets/img/iconsSvg/downloadTicketIcon.svg") no-repeat
    center;
}
.chevronDownIcon {
  width: 100%;
  height: 100%;
  background-color: #144fa9;
  -webkit-mask: url("../../../assets/img/iconsSvg/chevronDown.svg") no-repeat
    center;
  mask: url("../../../assets/img/iconsSvg/chevronDown.svg") no-repeat center;
}
</style>